@import 'primereact/resources/themes/arya-green/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import '_core.scss';
@import '_overrides.scss';
@import 'node_modules/primeflex/primeflex.scss';

@import '_header.scss';
@import '_content.scss';
@import '_databaselaika.scss';
@import '_report.scss';

.p-autocomplete-multiple-container{
  overflow: auto;
  max-width: calc(100vw - 4.5rem);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--surface-d);
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 6px;
}

.p-w-60{
  width: 60px;
}
