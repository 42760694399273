.datatable-laika .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.datatable-laika .p-datatable-items td.p-cell-editing {
    padding-top: 0;
    padding-bottom: 0;
}

.datatable-laika .p-datatable.p-datatable-items .p-filter-column .p-inputtext,
.datatable-laika .p-datatable.p-datatable-items .p-filter-column .p-inputnumber,
.datatable-laika .p-datatable.p-datatable-items .p-filter-column .p-multiselect {
    width: 100%;
}

.datatable-laika .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;
}

.datatable-laika .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
}

.datatable-laika .table-header {
    display: flex;
    justify-content: space-between;
}

.datatable-laika .p-datepicker {
    min-width: 25rem;
}

.datatable-laika .p-datepicker td {
    font-weight: 400;
}

.datatable-laika .p-datatable.p-datatable-items .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}

.datatable-laika .p-datatable.p-datatable-items .p-paginator {
    padding: 1rem;
}

.datatable-laika .p-datatable.p-datatable-items .p-datatable-thead > tr > th {
    text-align: left;
}

.datatable-laika .p-datatable.p-datatable-items .p-datatable-tbody > tr > td {
    cursor: auto;
}

.datatable-laika .overfloe-auto {
    overflow: auto;
}

.datatable-laika .p-datatable.p-datatable-items .p-datatable-thead > tr > th.p-selection-column,
.datatable-laika .p-datatable.p-datatable-items .p-datatable-tbody > tr > td.p-selection-column{
    overflow: hidden;
}


.datatable-laika .p-datatable.p-datatable-items .p-datatable-thead > tr > th.column-hide-laika,
.datatable-laika .p-datatable.p-datatable-items .p-datatable-tbody > tr > td.column-hide-laika {
    visibility: hidden;
    width: 0%;
}



.datatable-laika .p-datatable.p-datatable-items .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.datatable-laika .p-datatable-items .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 960px) {
    .datatable-laika .p-datatable.p-datatable-items .p-datatable-thead > tr > th.column-hide-laika,
    .datatable-laika .p-datatable.p-datatable-items .p-datatable-tbody > tr > td.column-hide-laika {
        display: none !important;
    }

    .datatable-laika.datatable-laika-mobile .p-datatable.p-datatable-items .p-datatable-thead > tr > th,
    .datatable-laika.datatable-laika-mobile .p-datatable.p-datatable-items .p-datatable-tfoot > tr > td {
        /*display: none !important;*/
    }

    .datatable-laika .p-datatable.p-datatable-items .p-filter-column .p-calendar{
      /*width: 100%;*/
    }

    .datatable-laika .p-datatable.p-datatable-items .p-datatable-thead > tr,
    .datatable-laika .p-datatable.p-datatable-items .p-datatable-tfoot > tr,
    .datatable-laika .p-datatable.p-datatable-items .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);
    }

    .datatable-laika .p-datatable.p-datatable-items .p-datatable-thead > tr > th,
    .datatable-laika .p-datatable.p-datatable-items .p-datatable-tfoot > tr > td,
    .datatable-laika .p-datatable.p-datatable-items .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-laika .p-datatable.p-datatable-items .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }

    .datatable-laika .p-paginator .p-paginator-current,
    .datatable-laika .p-paginator .p-paginator-left-content,
    .datatable-laika .p-paginator .p-paginator-right-content{
        width: 100%;
        text-align: center;
    }

    .datatable-laika .p-toolbar{
      flex-direction: column;
    }

    .datatable-laika .p-toolbar > *{
      margin: 4px;
    }
}

.p-tag-laika{
  width: 100%;
}

@media screen and (max-width: 960px) {
  .p-tag-laika{
    min-width: 100px;
    width: auto;
  }
}
