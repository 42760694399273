.layout-header {
    background-color: var(--surface-a);
    padding: 0;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 997;
    box-shadow: 0 0 4px rgba(0,0,0,0.25);
    border-bottom: 1px solid var(--surface-d);
    display: flex;
    align-items: center;
    padding: 0 35px;

    .menu-button {
        display: none;
        color: var(--text-color);
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        transition: background-color .2s;
        cursor: pointer;

        &:hover {
            background-color: var(--surface-c);
        }

        i {
            font-size: 24px;
            line-height: inherit;
        }
    }

    .header-logo{
      display: flex;
    }

    .logo {
        svg {
            height: 40px;
            width: auto;
        }

        &:focus {
            outline: 0 none;
            transition: box-shadow .2s;
            box-shadow: 0 0 0 0.2rem $focusBorderColor;
        }
    }

    .header-menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 100%;
        margin-left: auto;
        display: flex;

        > li {
            height: 70px;
            line-height: 70px;

            > a,
            > .p-link {
                text-decoration: none;
                color: var(--text-color);
                /*min-width: 120px;*/
                margin: 0 8px;
                font-size: 16px;
                display: block;
                text-align: center;
                user-select: none;
                line-height: 68px;
                border-bottom: 2px solid transparent;
                border-radius: 0;
                transition: border-bottom-color .2s;
                cursor: pointer;

                &:hover, &:focus {
                    border-bottom-color: var(--primary-color);
                }

                &:focus {
                    z-index: 1;
                    outline: 0 none;
                    transition: box-shadow .2s;
                    box-shadow: inset 0 0 0 0.2em $focusBorderColor;
                }

                &.p-selected {
                    color: var(--primary-color);
                }

            }

            &.header-submenu {
                position: relative;

                > ul {
                    position: absolute;
                    transform-origin: top;
                    top: 70px;
                    right: 0;
                    width: 275px;
                    max-height: 400px;
                    background-color: var(--surface-f);
                    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
                    overflow: auto;
                    list-style-type: none;
                    padding: 1rem;
                    margin: 0;
                    border-radius: 3px;

                    > li {
                        line-height: 1;

                        &.header-submenu-header {
                            display: block;
                            color: var(--text-color-secondary);
                            font-weight: 600;
                            user-select: none;
                            padding: 1.5rem 0 1rem 0;
                            font-size: 0.857rem;
                            text-transform: uppercase;

                            &:first-child {
                                padding-top: 1rem;
                            }
                        }
                    }

                    a,
                    .p-link {
                        text-decoration: none;
                        color: var(--text-color);
                        padding: .5rem;
                        display: flex;
                        align-items: center;
                        user-select: none;
                        border-radius: 3px;
                        cursor: pointer;
                        width: 100%;

                        &:hover {
                            background-color: var(--surface-c);
                        }

                        span {
                            margin-left: .5rem;
                        }

                        i {
                            font-size: 18px;
                            color: var(--text-color-secondary);
                        }

                        img {
                            width: 32px;
                            margin-right: .5rem;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 960px) {
  .layout-header{
    flex-direction: column;
    height: 96px;
    .header-logo{
      margin-top: 8px;
    }
    .header-menu{
      margin: 0;
      > li {
          height: 40px;
          line-height: 40px;

          > a,
          > .p-link {
              line-height: 38px;
          }
      }
    }
  }
}

@media print {
   .layout-header {
      visibility: hidden;
    }
}
