$linkColor: #2196f3;
$focusBorderColor:#BBDEFB;

html {
    font-size: 14px;
}

body {
    margin: 0px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--surface-a);
    font-family: var(--font-family);
    font-weight: normal;
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1rem 0;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
    color: inherit;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

p {
    line-height: 1.5;
    margin: 0 0 1rem 0;
}

input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, 0.1)
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3)
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1)
    }
}

pre[class*="language-"] {
    &:before, &:after {
        display: none !important;
    }

    code {
        border-left: 10px solid var(--surface-d) !important;
        box-shadow: none !important;
        background: var(--surface-e) !important;
        margin: 1em 0;
        color: var(--text-color);
        font-size: 14px;

        .token {
            &.tag,
            &.keyword {
                color: #2196F3 !important;
            }

            &.attr-name,
            &.attr-string {
                color: #2196F3 !important;
            }

            &.attr-value {
                color: #4CAF50 !important;
            }

            &.punctuation {
                color: var(--text-color);
            }

            &.operator,
            &.string {
                background: transparent;
            }
        }
    }
}

.p-toast.p-toast-top-right,
.p-toast.p-toast-top-left {
    top: 100px;
}

.action-button {
    font-weight: bold;
    text-align: center;
    color: #ffffff !important;
    background-color: #fe8702;
    padding: 10px 24px 9px 24px;
    border-radius: 3px;
    transition: background-color .2s;

    &:hover {
        background-color: #c56a05;
        color: #ffffff;
    }
}

.liveEditorSplitButton {
    .p-splitbutton-defaultbutton {
        .p-button-label {
            display: none;
        }
    }
}

.liveEditorPanel {
    .p-menuitem-link {
        height: 38px;

        img {
            margin-left: -4px;
            width: 20px;
            height: 20px;
        }
    }
}
