.report-body{
  table{
    width: 100%;
    border-collapse:collapse;
    border: 1px;
    tr{
      border: 1px solid #38383A !important;
    }
    th{
      background: var(--primary-color) !important;
      color: var(--primary-color-text) !important;
      min-width: 100px;
    }
    td{
      min-width: 100px;
    }
  }
  table.noborder{
    border: none;
    tr{
      border: none !important;
    }
  }
  .only-print{
    visibility: hidden;
    width: 0%;
  }
  .modulo-background{
    position: relative;
    img {
      width: 100%;
    }
    span,b,i,u{
      position: absolute;
      font-size: 1.2vw;
      color: #000000;
      background: #FFFFFF;
    }
    span[contenteditable],
    b[contenteditable],
    i[contenteditable],
    u[contenteditable]{
      color: #FFFFFF;
      background: #000000;
    }
  }
  .modulo{
    span[contenteditable],
    b[contenteditable],
    i[contenteditable],
    u[contenteditable]{
      color: #000000;
      background: #FFFFFF;
      border: 1px solid 000000;
    }
  }
  @media screen {
    div.reportHeader,
    div.reportFooter {
      /*display: none;*/
    }
  }
}
@media print {
  .report-body{
    color: #000000;
    table{
      background: #FFFFFF !important;
      tr{
        border: none !important;
      }
      th{
        background: var(--primary-color) !important;
        color: var(--primary-color-text) !important;
        border: 1px solid !important;
        padding: 0.5rem !important;
        font-weight: bolder !important;
      }
      td{
        background: #FFFFFF;
        color: #000000;
        border: 1px solid !important;
        padding: 0.5rem !important;
        font-weight: normal !important;
      }
    }
    table.noborder{
      border: none !important;
      tr{
        border: none !important;
      }
      th{
        border: none !important;
      }
      td{
        border: none !important;
      }
    }
    .only-print{
      visibility: visible;
      width: auto;
    }
    .p-datatable-header{
      display: none;
    }
    .card{
      margin: 0;
      padding: 0;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }
    .p-toolbar{
      display: none;
    }
    .modulo-background{
      span,b,i,u,
      span[contenteditable],
      b[contenteditable],
      i[contenteditable],
      u[contenteditable]{
        color: #000000;
        background: #FFFFFF;
      }
    }
    .modulo{
      padding: 2em;
    }
    .modulo-withHeader{
      padding-top: 4em;
    }
    .modulo-withFooter{
      padding-bottom: 4em;
    }
    .pagebreak { page-break-after: always; } /* page-break-after works, as well */
    @media print {
      div.reportHeader {
        position: fixed;
        padding: 1em 2em;
        top: 0;
      }
      div.reportFooter {
        position: fixed;
        padding: 1em 2em;
        bottom: 0;
      }
    }
  }
}
